/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Flexbox from 'vux/src/components/flexbox/flexbox';
import FlexboxItem from 'vux/src/components/flexbox/flexbox-item';
import ViewBox from 'vux/src/components/view-box';
import XButton from 'vux/src/components/x-button';
import Draggable from 'vuedraggable';
export default {
  name: 'DoorControl',
  components: {
    XButton: XButton,
    ViewBox: ViewBox,
    Flexbox: Flexbox,
    FlexboxItem: FlexboxItem,
    Draggable: Draggable
  },
  data: function data() {
    return {
      userSerial: 0,
      doors: [],
      options: {
        // delay: 1000,
        disabled: true,
        animation: 150,
        scroll: true
      },
      showFlag: false,
      msg: '',
      openDoorMsg: '您要开的门不存在， 请刷新稍候再试',
      //开门提示框信息
      openDoorTipsShow: false,
      openDoorState: '' //开门状态  0：失败 1：成功

    };
  },
  methods: {
    onOpen: function onOpen(door) {
      var _this2 = this;

      if (this.showFlag) {
        return;
      }

      this.$utils.Tools.loadShow('远程开门中');
      var ua = navigator.userAgent.toLowerCase();

      if (/iphone|ipad|ipod/.test(ua)) {
        this.$refs.okAudio.play();
        this.$refs.okAudio.pause();
        this.$refs.noAudio.play();
        this.$refs.noAudio.pause();
      }

      this.$api.Door.openDoor({
        gateBh: door.gateBh,
        userSerial: this.userSerial
      }).then(function (response) {
        var _this = _this2;

        _this2.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg; // 开门失败

        if (+code !== _this2.$code.success) {
          door.flag = 0;

          _this.$refs.noAudio.pause();

          _this.$refs.noAudio.play();

          _this.openDoorMsg = msg; //开门状态设置为0，即开门失败

          _this.openDoorState = 0; //开门提示显示

          _this.openDoorTipsShow = true; // 一秒后提示框关闭

          setTimeout(function () {
            _this.openDoorTipsShow = false;
          }, 2000);
          setTimeout(function () {
            door.flag = -1;
          }, 2000);
        } else {
          door.flag = 1;

          _this.$refs.okAudio.pause();

          _this.$refs.okAudio.play(); // this.$utils.Tools.toastSuccess(msg)


          _this.openDoorState = 1;
          _this.openDoorMsg = msg;
          _this.openDoorTipsShow = true;
          setTimeout(function () {
            _this.openDoorTipsShow = false;
          }, 2000);
          setTimeout(function () {
            door.flag = -1;
          }, 2000);
        }
      }).catch(function () {
        _this2.$utils.Tools.httpError();
      });
    },
    // 点击设置按钮
    onSetting: function onSetting() {
      this.showFlag = true;
      this.options.disabled = false;
    },
    //取消设置
    onCancel: function onCancel() {
      this.showFlag = false;
      this.options.disabled = true;
      this.getDoor();
    },
    //更新设置
    onUpdate: function onUpdate() {
      var _this3 = this;

      this.showFlag = false;
      this.options.disabled = true; // 循环门列表

      for (var index in this.doors) {
        // 将门当前的索引赋值给门的gateOrder字段
        this.doors[index].gateOrder = index;
      }

      this.$api.Door.updateDoor({
        doorList: this.doors,
        userSerial: this.userSerial
      }).then(function (response) {
        var code = response.code,
            msg = response.msg;

        _this3.$utils.Tools.toastCustom(code, msg);
      }).catch(function () {
        _this3.$utils.Tools.httpError();
      });
    },
    //获取门列表
    getDoor: function getDoor() {
      var _this4 = this;

      this.$api.Door.getDoor({
        userSerial: this.userSerial
      }).then(function (response) {
        _this4.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this4.$code.success) {
          _this4.$utils.Tools.toastShow(msg);
        } else {
          _this4.doors = data;

          for (var index in _this4.doors) {
            _this4.$set(_this4.doors[index], 'flag', -1);
          } //若返回的门列表为空,则表示该用户没有任何门的操作权限,提示相关信息


          if (_this4.doors.length === 0) {
            _this4.$utils.Tools.toastShow('可操作的门列表为空,请联系管理员设置!'); //2秒后返回上一页


            setTimeout(function () {
              _this4.$router.go(-1);
            }, 2000);
          }
        }
      }).catch(function () {
        _this4.$utils.Tools.httpError();
      });
    }
  },
  created: function created() {
    this.$utils.Tools.loadShow();
    this.userSerial = this.$utils.Store.getItem('userSerial');
    this.getDoor();
  }
};